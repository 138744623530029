<template>
    <div>
        <v-card>
            <v-card-title>Paciente</v-card-title>
            <v-card-subtitle>
                <v-combobox :items="pacientesFromDB"
                            label="Paciente"
                            item-value="id"
                            clearable
                            item-text="nombreCompleto"
                            :filter="searchPaciente"
                            @keydown.enter="getPacientes($event)"
                            cache-items
                            v-model="pacienteSeleccionado"
                            :search-input.sync="search"
                            solo>
                    <template slot="selection" slot-scope="data">
                        <v-list-item-content>
                            <v-list-item-title v-text="data.item.nombreCompleto"></v-list-item-title>
                            <v-list-item-subtitle v-text="data.item.cedula"></v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <template slot="item" slot-scope="data">
                        <v-list-item-content>
                            <v-list-item-title v-text="data.item.nombreCompleto"></v-list-item-title>
                            <v-list-item-subtitle v-text="data.item.cedula"></v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-combobox>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
                <div class="container">
                    <v-row dense>
                        <v-col cols="4">
                            <label class="font-weight-black">Código:</label>
                        </v-col>
                        <v-col cols="8">
                            <label>
                                {{
                                pacienteSeleccionado !== null && pacienteSeleccionado !== null
                                    ? pacienteSeleccionado.id
                                    : ""
                                }}
                            </label>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="4">
                            <label class="font-weight-black">Nombre:</label>
                        </v-col>
                        <v-col cols="8">
                            <label>
                                {{
                                pacienteSeleccionado !== null && pacienteSeleccionado !== null
                                    ? pacienteSeleccionado.nombreCompleto
                                    : ""
                                }}
                            </label>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="4">
                            <label class="font-weight-black">Cedula:</label>
                        </v-col>
                        <v-col cols="8">
                            <label>
                                {{
                                pacienteSeleccionado !== null && pacienteSeleccionado !== null
                                    ? pacienteSeleccionado.cedula
                                    : ""
                                }}
                            </label>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
        <Overlay :overlay="overlay"></Overlay>
    </div>
</template>

<script>
import axios from "../../shared/jwtAxios.js";
import { isEmpty } from "../../utility/jwtHelper";
import Overlay from "../helpers/Overlay.vue";

export default {
  name: "paciente-header",
    components: { Overlay },
  props: {
    limpiaPacienteSeleccionado: false,
  },
  created() {
    this.getPacientes();
  },
  data() {
    return {
      loading: false,
      pacientes: [],
      pacientesFromDB: [],
      pacienteSeleccionado: null,
      search: "", 
        overlay: false
    };
  },
  methods: {
    getPacientes(event) {
        if (event === 'undefined' || event === undefined)
            this.overlay = true;
      axios
        .get(
          this.$apiConfig.pacientesUrl +
            "/getPacientesByCuenta", {
              params: {
                  cuentaId: this.$store.state.cuentaId, 
                  nombrePaciente: this.search, 
                  cedula: this.search
              }
            }
        )
        .then((response) => {
          this.pacientesFromDB = response.data;
          this.overlay = false;
          this.search = "";
        })
        .catch((error) => {
           this.overlay = false;
            this.search = "";
          this.$errorCargarDatos();
          window.console.log(error);
        });
    },
      searchPaciente(item, queryText, itemText) {
          return ( item.nombreCompleto.toLowerCase().includes(queryText.toLowerCase()) ||  item.cedula.includes(queryText) );
          // this.filtrarPacientes(val);
      },
    // filtrarPacientes(val) {
    //   if (val) {
    //     this.loading = true;
    //     setTimeout(() => {
    //       if (this.pacientesFromDB.length > 0) {
    //          this.pacientes = this.pacientesFromDB.filter((p) => {
    //          return (p.nombreCompleto.toLowerCase().includes(val.toLowerCase()) || p.cedula.includes(val));
    //         });
    //       }
    //       this.loading = false;
    //     }, 500);
    //   }
    // }, 
    
  },
  watch: {
      pacienteSeleccionado(val) {
          if (val !== null && val !== undefined) {
              if (isEmpty(val)) {
                  return;
              }
              this.$emit("pacienteSeleccionado", val);
          }
      },
      limpiaPacienteSeleccionado(val) {
          if (val) {
              this.pacienteSeleccionado = {};
          }
      },
    // searchPaciente(item, queryText, itemText) {
    //     return
    //     (
    //         item.nombreCompleto.toLowerCase().indexOf(queryText) > -1 ||
    //             item.cedula.indexOf(queryText) > -1
    //     );
    //   // this.filtrarPacientes(val);
    // },
  },
};
</script>

<!--      <v-autocomplete-->
<!--        :loading="loading"-->
<!--        class="mt-4"-->
<!--        hide-no-data-->
<!--        label="Buscar paciente..."-->
<!--        hide-details-->
<!--        item-text="nombreCompleto"-->
<!--        item-value="id"-->
<!--        :items="pacientesFromDB"-->
<!--        @keyup.enter="getPacientes()"-->
<!--        dense-->
<!--        outlined-->
<!--        :filter="searchPaciente"-->
<!--        append-icon="null"-->
<!--        v-model="pacienteSeleccionado"-->
<!--        return-objectnt-->
<!--      >-->
<!--        <template v-slot:no-data>-->
<!--          <v-list-item>-->
<!--            <v-list-item-title-->
<!--              >Busque los articulos que desee facturar</v-list-item-title-->
<!--            >-->
<!--          </v-list-item>-->
<!--        </template>-->

<!--        <template v-slot:selection="{ attr, on, item, selected }">-->
<!--          <v-chip-->
<!--            v-bind="attr"-->
<!--            :input-value="selected"-->
<!--            style="background-color:transparent;"-->
<!--            v-on="on"-->
<!--          >-->
<!--            <span v-text="item.nombreCompleto"></span>-->
<!--            <span v-text="item.cedula" class="ml-4"></span>-->
<!--          </v-chip>-->
<!--        </template>-->

<!--        <template v-slot:item="{ item }">-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title v-text="item.nombreCompleto"></v-list-item-title>-->
<!--            <v-list-item-subtitle v-text="item.cedula"></v-list-item-subtitle>-->
<!--          </v-list-item-content>-->
<!--        </template>-->
<!--      </v-autocomplete>-->