<template>
    <div>
        <v-container id="medicamentos-hc">
            <v-row>
                <v-col cols="4" md="4">
                    <v-menu
                            ref="menuFecha"
                            v-model="menuFecha"
                            :return-value.sync="fecha"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    dense
                                    outlined
                                    v-model="fecha"
                                    label="Fecha"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                            ></v-text-field>
                        </template>

                        <v-date-picker
                                @click="menuFecha = false"
                                @input="$refs.menuFecha.save(fecha)"
                                v-model="fecha"
                                no-title
                                scrollable
                        >
                            <!-- <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menuFecha = false"
                              >Cancelar</v-btn
                            >
                            <v-btn text color="primary" @click="$refs.menuFecha.save(fecha)"
                              >OK</v-btn
                            > -->
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6">
                    <v-row> </v-row>
                    <v-row><h5>Medicamentos</h5></v-row>
                    <v-row>
                        <v-data-table
                                :headers="medicamentosHeader"
                                :items="medicamentosFiltrados"
                                :items-per-page="5"
                                class="elevation-1"
                        >
                            <template v-slot:top>
                                <div class="mx-4">
                                    <v-row>
                                        <v-text-field
                                                @keydown.enter.prevent="
                      guardarMedicamento(searchMedicamento)
                    "
                                                v-model="searchMedicamento"
                                                id="txtBuscar"
                                                label="Buscar"
                                                ref="txtBuscar"
                                        ></v-text-field>
                                    </v-row>
                                </div>
                            </template>

                            <template v-slot:item.actions="{ item }">
              <span>
                <v-icon color="primary" @click="editarMedicamento(item)"
                >mdi-pencil</v-icon
                >
              </span>
                                <span>
                <v-icon color="primary" @click="indicarMedicamento(item)"
                >mdi-share</v-icon
                >
              </span>
                            </template>
                        </v-data-table>
                    </v-row>

                    <v-btn
                            class="mt-5"
                            @click="indicarDesdePlantilla()"
                            text
                            rounded
                            color="primary"
                    >
                        <v-icon left>mdi-vector-selection</v-icon>Indicar desde plantilla
                    </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                    <v-row>
                        <h5>Medicamentos indicados</h5>
                    </v-row>

                    <v-row>
                        <v-simple-table fixed-header height="300px">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Descripcion</th>
                                    <th>Dosis</th>
                                    <th>Vía</th>
                                    <th>Uso</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in medicamentosSeleccionados" :key="item.id">
                                    <td>
                                        <label>{{ item.id }}</label>
                                    </td>
                                    <td>
                                        <label>{{ item.descripcion }}</label>
                                    </td>
                                    <td>
                                        <v-text-field
                                                v-model="item.dosis"
                                                label="Dosis"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="item.via" label="Vía"></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="item.uso" label="Uso"></v-text-field>
                                    </td>
                                    <td>
                                        <v-icon
                                                color="secondary"
                                                @click="eliminarMedicamentoIndicado(item)"
                                        >mdi-delete</v-icon
                                        >
                                    </td>
                                </tr>
                                </tbody>
                            </template>

                            <template
                                    v-slot:bottom
                                    v-if="
                medicamentosSeleccionados &&
                medicamentosSeleccionados.length > 0
              "
                            >
                                <v-row class="text-right">
                                    <v-col cols="12">
                                        <v-btn @click="imprimir()" text rounded color="primary">
                                            <v-icon left>mdi-printer</v-icon>Imprimir
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn
                                                @click="guardarComoPlantilla()"
                                                text
                                                rounded
                                                color="primary"
                                        >
                                            <v-icon left>mdi-check</v-icon>Guardar como plantilla
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-simple-table>
                    </v-row>

                    <v-row>
                        <v-text-field
                                class="mt-2"
                                id="txtObservacion"
                                dense
                                outlined
                                label="Observación (opcional)"
                                v-model="observacion"
                        ></v-text-field>
                    </v-row>
                </v-col>

                <registro-plantilla
                        :key="plantillakey"
                        v-on:cerrarModal="cerrarModalPlantilla()"
                        :contexto="contexto"
                        v-on:onPlantillaGuardada="reRenderListadoPlantillas"
                        :dialog.sync="dialogPlantilla"
                ></registro-plantilla>

                <listado-plantillas
                        :key="plantillaskey"
                        :dialog.sync="dialogPlantillas"
                        :contexto="contexto"
                        v-on:onItemSeleccionado="onPlantillaSeleccionada"
                        v-on:cerrarModal="cerrarModalPlantillas()"
                ></listado-plantillas>
            </v-row>
        </v-container>
        <loading-dialog :dialog="dialogImpresion" :mensaje="mensajeImpresion"></loading-dialog>
    </div>
</template>

<script>
import jwtAxios from "../../../shared/jwtAxios.js";
import { imprimir } from "../../../utility/reporting.js";
import { contextoPlantillaIndicacion } from "../../../utility/enumerations.js";
import registroPlantilla from "../indicaciones/plantillas/registroplantilla.vue";
import listadoPlantillas from "../indicaciones/plantillas/listadoplantillas.vue";
import { dateNow, removeTimeFromDate } from "../../../utility/dateHelpers.js";
import swal from "sweetalert";
import LoadingDialog from "../../dialogs/LoadingDialog.vue";


export default {
  name: "medicamentos-hc",
  components: {
      LoadingDialog,
    registroPlantilla,
    listadoPlantillas,
  },
  props: {
    fechaIndicacionMedicamentos: "",
    observacionIndicacionMedicamentos: "",
    nombrePaciente: "",
    historiaClinicaId: String,
  },
  created() {
    this.getMedicamentos();
    this.getMedicamentosByHistoriaId();
  },
  data() {
    return {
      plantillakey: 0,
      plantillaskey: 50,
      contexto: "",
      dialogPlantilla: false,
      dialogPlantillas: false,
      tabIndicacion: null,
      medicamentosUrl: this.$apiConfig.medicamentosUrl,
      medicamento: {
        id: 0,
        cuentaId: 0,
        descripcion: "",
        text: "",
        precio: 0,
        dosis: "",
        via: "",
        uso: "",
        activo: true,
      },
      editing: null,
      editingIndex: -1,
      nonce: 1,
      searchMedicamento: "",
      medicamentos: [],
      medicamentosSeleccionados: [],
      medicamentosHeader: [
        { text: "Id", value: "id" },
        { text: "Descripción", value: "descripcion" },
        { text: "", value: "actions", sortable: false },
      ],
      fecha: dateNow(),
      menuFecha: false,
      observacion: "",
        dialogImpresion: false,
        mensajeImpresion: "Imprimiendo..."
    };
  },
  methods: {
    //MEDICAMENTOS
    guardarComoPlantilla() {
      this.contexto = contextoPlantillaIndicacion.MEDICAMENTO;
      this.dialogPlantilla = true;
    },
    editarMedicamento(item) {
      this.medicamento.id = item.id;
      this.searchMedicamento = item.descripcion;
      this.$refs.txtBuscar.focus();
    },
    getMedicamentos() {
      jwtAxios
        .get(`${this.medicamentosUrl}/${this.$store.state.cuentaId}`)
        .then(({ data }) => {
          this.medicamentos = data;
        })
        .catch((error) => {
          window.console.log(error);
          this.$errorCargarDatos();
        });
    },
    getMedicamentosByHistoriaId() {
      jwtAxios
        .get(`${this.medicamentosUrl}/Mhistoria/${this.historiaClinicaId}`)
        .then((response) => {
          this.medicamentosSeleccionados = response.data;
          this.$store.commit("setMedicamentos", response.data);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    insertar() {
      jwtAxios
        .post(this.medicamentosUrl, this.medicamento)
        .then(() => {
          this.$datosGuardados();
          this.getMedicamentos();
          this.limpiar();
        })
        .catch((error) => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    actualizar() {
      jwtAxios
        .put(this.medicamentosUrl, this.medicamento)
        .then(() => {
          this.$datosGuardados();
          this.getMedicamentos();
          this.limpiar();
        })
        .catch((error) => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    guardarMedicamento(descripcion) {
      if (descripcion === null || descripcion.length <= 0) {
        return;
      }
      this.medicamento.cuentaId = Number.parseInt(this.$store.state.cuentaId);
      this.medicamento.descripcion = descripcion;
      if (this.medicamento.id > 0) {
        this.actualizar();
      } else {
        this.insertar();
      }
    },
    reRenderRegistroPlantilla() {
      this.plantillakey += 20;
    },
    reRenderListadoPlantillas() {
      this.plantillaskey += 40;
    },
    imprimir() {
        this.dialogImpresion = true;
      let array = JSON.stringify(this.medicamentosSeleccionados);
      let parametros = {
        nombrePaciente: this.nombrePaciente,
        fecha: this.fecha,
        observacion: this.observacion,
        nombreReporte: this.$store.state.reporteMedicamentos,
        array: array,
      };
      
      setTimeout(() => {
          this.dialogImpresion = false;
      }, 3000)

      imprimir(`${this.$apiConfig.medicamentosUrl}/imprimir/`, parametros, this.nombrePaciente);
    },
    indicarMedicamento(item) {
      let med = {};
      if (this.medicamentosSeleccionados !== null) {
        med = this.medicamentosSeleccionados.find((med) => med.id == item.id);
      }
      if (med !== undefined && med !== null && med.id > 0) {
        swal({
          title: "Advertencia!",
          icon: "warning",
          text: "Este articulo ya ha sido agregado.",
          button: {
            confirm: {
              text: "Ok",
              value: true,
              closeModal: true,
            },
          },
        });
        return;
      } else {
        if (
          this.medicamentosSeleccionados === null ||
          this.medicamentosSeleccionados === undefined
        ) {
          this.medicamentosSeleccionados = [];
        }
        this.medicamentosSeleccionados.push(item);
      }
    },
    indicarDesdePlantilla() {
      this.dialogPlantillas = true;
      this.contexto = contextoPlantillaIndicacion.MEDICAMENTO;
    },
    onPlantillaSeleccionada(plantilla) {
      this.medicamentosSeleccionados = plantilla.detalle;
    },
    eliminarMedicamentoIndicado(item) {
      this.medicamentosSeleccionados.splice(
        this.medicamentosSeleccionados.indexOf(item),
        1
      );
    },
    limpiar() {
      this.searchMedicamento = "";
    },
    cerrarModalPlantilla() {
      this.dialogPlantilla = false;
      this.contexto = "";
      this.reRenderRegistroPlantilla();
    },
    cerrarModalPlantillas() {
      this.dialogPlantillas = false;
      this.contexto = "";
    },
  },
  computed: {
    medicamentosFiltrados: function () {
      return (
        this.medicamentos.filter((item) => {
          return item.descripcion
            .toUpperCase()
            .includes(this.searchMedicamento.toUpperCase());
        }) || []
      );
    },
  },
  watch: {
    medicamentosSeleccionados: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$store.commit("setMedicamentos", newValue);
      },
    },
    fecha: function (value) {
      this.$store.commit("setFechaIndicacionMedicamentos", value);
    },
    fechaIndicacionMedicamentos: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== null && newValue !== undefined) {
          this.fecha = removeTimeFromDate(newValue) || dateNow();
        }
      },
    },
    observacion: function (newValue) {
      this.$store.commit("setObservacionIndicacionMedicamentos", newValue);
    },
    observacionIndicacionMedicamentos: {
      immediate: true,
      handler(newValue) {
        this.observacion = newValue;
      },
    },
  },
};
</script>

<style>
h5 {
  font-size: 0.9em;
  margin-bottom: 10px;
}
</style>
