<template>
  <v-overlay :value="overlay">
      <v-container>
         <p><v-progress-circular  indeterminate size="64"></v-progress-circular></p>
      </v-container>
  </v-overlay>
</template>

<script>
export default {
  name: "overlay",
  props: {
    overlay: false,
  }
};
</script>
