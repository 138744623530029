var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('h1',[_vm._v("Listado de facturas")])]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"2","sm":"12","xs":"12"}},[_c('v-menu',{ref:"menuFechaDesde",attrs:{"return-value":_vm.fechaDesde,"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.fechaDesde=$event},"update:return-value":function($event){_vm.fechaDesde=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Fecha desde","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaDesde),callback:function ($$v) {_vm.menuFechaDesde=$$v},expression:"menuFechaDesde"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuFechaDesde = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menuFechaDesde.save(_vm.fechaDesde)}}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"12","xs":"12"}},[_c('v-menu',{ref:"menuFechaHasta",attrs:{"return-value":_vm.fechaHasta,"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.fechaHasta=$event},"update:return-value":function($event){_vm.fechaHasta=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Fecha hasta","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaHasta),callback:function ($$v) {_vm.menuFechaHasta=$$v},expression:"menuFechaHasta"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuFechaHasta = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menuFechaHasta.save(_vm.fechaHasta)}}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"1","sm":"12","xs":"12","align":"right"}},[_c('v-btn',{attrs:{"outlined":"","dense":"","color":"primary","rounded":""},on:{"click":function($event){return _vm.getFacturas()}}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v("Buscar ")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Buscar facturas por paciente","dense":"","outlined":""},model:{value:(_vm.busqueda),callback:function ($$v) {_vm.busqueda=$$v},expression:"busqueda"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"disable-sort":"","headers":_vm.facturaHeaders,"items":_vm.facturasFiltradas,"item-key":"id","group-by":"paciente.id"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-minus" : "mdi-plus"))]),_vm._v(" "+_vm._s(items[0].paciente.nombreCompleto)+" ")],1),_c('th',[_vm._v(_vm._s(items[0].fecha))]),_c('th',[_vm._v(_vm._s(items[0].totalDiferencia))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.facturaDetalleHeaders,"items":item.articulos}})]}},{key:"body.append",fn:function(){return [_c('tr',{class:{ 'v-data-table__mobile-table-row': _vm.isMobile }},[_c('td',{class:{ 'v-data-table__mobile-row': _vm.isMobile },attrs:{"colspan":("" + (_vm.isMobile ? 6 : 2))}},[_c('h3',[_vm._v(" Total facturado: "+_vm._s(_vm.totalFacturado)+" ")])])])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }