import jsPDF from "jspdf";
import qs from "qs";
import "jspdf-autotable";
import store from "../state/index.js";
import { parseJwt } from "../utility/jwtHelper.js";
import { getCurrentDate } from "../utility/dateHelpers.js";
import axios from "@/shared/jwtAxios";

const date = getCurrentDate();
const fecha = `${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`;
// const downloadsFolder = require("downloads-folder");

//ESTILOS DEL REPORTE
const tableHeadStyles = {
  fillColor: "white",
  textColor: "black",
  fontStyle: "bold",
  cellPadding: 0.01,
};
const tableBodyStyles = {
  fontSize: 8,
  cellPadding: 0.01,
};
const tableBodyTheme = "striped";
const tableIndicacionBodyTheme = "plain";
const alignLeft = "left";
const alignRight = "right";
const alignCenter = "center";
const localizacionPiePagina = 10.6;
const marginLeft = 0.4;
const paperSizeLetter = [8.5, 11];

//DATOS DESDE VUEX
const firmaDoctor = () => parseJwt(store.state.jwtToken).firma || "";

const getConfiguracionReporte = () => store.state.configuracionReporte;

//HISTORIA CLINICA
function imprimirHistoriaClinica(historiaClinica) {
  const pdf = new jsPDF("portrait", "in", paperSizeLetter);

  setFecha(pdf);

  pdf.setFontSize(16);
  pdf.setFont("Helvetica", "bold");
  pdf.text("HISTORIA CLINICA", marginLeft, 0.4);

  pdf.setFont("Helvetica", "normal");
  pdf.setLineWidth(0.01);
  pdf.line(marginLeft, 0.6, 8, 0.6);

  pdf.setFont("times", "bold");
  pdf.setFontSize(13);
  pdf.text(`${historiaClinica.paciente.nombreCompleto}`, marginLeft, 0.9);

  pdf.setLineWidth(0.01);
  pdf.line(marginLeft, 1.1, 8, 1.1);

  var finalY = pdf.lastAutoTable.finalY || 1;
  pdf.text("Motivo de consulta", marginLeft, finalY + 0.5);
  pdf.autoTable({
    theme: tableBodyTheme,
    headStyles: tableHeadStyles,
    startX: marginLeft,
    startY: finalY + 0.7,
    head: [["Motivo de consulta", "Historia de la enfermedad"]],
    body: [
      [historiaClinica.motivoConsulta, historiaClinica.historiaEnfermedad],
    ],
    margin: { left: marginLeft },
  });

  finalY = pdf.lastAutoTable.finalY;
  pdf.text("Antecedentes", marginLeft, finalY + 0.5);
  pdf.autoTable({
    theme: tableBodyTheme,
    headStyles: tableHeadStyles,
    startX: marginLeft,
    startY: finalY + 0.7,
    head: [
      [
        "Patológicos",
        "No patologicos",
        "Quirurgicos",
        "Familiares",
        "Ginecológicos",
        "Otros",
      ],
    ],
    body: [
      [
        historiaClinica.antecedentesPatologicos,
        historiaClinica.antecedentesNoPatologicos,
        historiaClinica.antecedentesQuirurgicos,
        historiaClinica.antecedentesFamiliares,
        historiaClinica.antecedentesGinecologicos,
        historiaClinica.antecedentesOtros,
      ],
    ],
    margin: { left: marginLeft },
  });

  finalY = pdf.lastAutoTable.finalY;
  pdf.text("Estudios realizados", marginLeft, finalY + 0.5);
  pdf.autoTable({
    theme: tableBodyTheme,
    headStyles: tableHeadStyles,
    startX: marginLeft,
    startY: finalY + 0.7,
    head: [["Sonografias", "Radiografías", "Laboratorios", "Otros"]],
    body: [
      [
        historiaClinica.sonografias,
        historiaClinica.radiografias,
        historiaClinica.laboratorios,
        historiaClinica.otrosEstudios,
      ],
    ],
    margin: { left: marginLeft },
  });

  finalY = pdf.lastAutoTable.finalY;
  pdf.text("Exámen físico", marginLeft, finalY + 0.5);
  pdf.autoTable({
    theme: tableBodyTheme,
    headStyles: tableHeadStyles,
    startX: marginLeft,
    startY: finalY + 0.7,
    head: [["Peso  -  lbs", "Talla  -  pie", "Temperatura  -  °C"]],
    body: [
      [
        historiaClinica.peso,
        historiaClinica.talla,
        historiaClinica.temperatura,
      ],
    ],
    margin: { left: marginLeft },
  });

  finalY = pdf.lastAutoTable.finalY;
  pdf.autoTable({
    theme: tableBodyTheme,
    headStyles: tableHeadStyles,
    startX: marginLeft,
    startY: finalY + 0.04,
    head: [
      [
        "Tensión arterial  -  mm Hg",
        "Frecuencia cardiaca  -  lat/min",
        "Frecuencia respiratoria  -  resp/min",
      ],
    ],
    body: [
      [
        historiaClinica.tensionArterial,
        historiaClinica.frecuenciaCardiaca,
        historiaClinica.frecuenciaRespiratoria,
      ],
    ],
    margin: { left: marginLeft },
  });

  if (historiaClinica.diagnosticos.length > 0) {
    finalY = pdf.lastAutoTable.finalY;
    pdf.text("Diagnosticos", marginLeft, finalY + 0.5);
    pdf.autoTable({
      theme: tableBodyTheme,
      headStyles: tableHeadStyles,
      startX: marginLeft,
      startY: finalY + 0.7,
      columns: [{ header: "", dataKey: "descripcion" }],
      body: historiaClinica.diagnosticos,
      margin: { left: marginLeft },
    });
  }

  setFirmaDoctor(pdf);
  setPiePagina(pdf);

  pdf.autoPrint();
  window.open(pdf.output("bloburl"), "_blank");
}

//FACTURA
function imprimirFactura(factura, paciente) {
  const pdf = new jsPDF("portrait", "in", paperSizeLetter);

  //TITULO
  setFecha(pdf);

  pdf.setFontSize(16);
  pdf.setFont("Helvetica", "bold");
  pdf.text("FACTURA", marginLeft, 0.4);

  pdf.setFont("Helvetica", "normal");
  //DATOS DEL CLIENTE
  pdf.setFontSize(16);
  pdf.setDrawColor(173, 197, 207);
  pdf.setLineWidth(0.02);
  pdf.line(marginLeft, 0.6, 8, 0.6);

  pdf.setFontSize(11);

  //LADO IZQUIERDO
  pdf.text(`Paciente: ${paciente.nombreCompleto}`, marginLeft, 0.8);
  pdf.text(`ARS: ${paciente.seguroMedico.descripcion}`, marginLeft, 1);
  pdf.text(`Cédula: ${paciente.cedula}`, marginLeft, 1.2);
  pdf.text(`F. Nac.: ${paciente.fechaNacimiento}`, marginLeft, 1.4);

  //LADO CENTRAL
  pdf.text(`NSS: ${paciente.numeroAsegurado}`, 3.8, 0.8);
  pdf.text(`Sexo: ${paciente.sexo}`, 3.8, 1);

  //LADO DERECHO
  pdf.text(`Fecha: ${factura.fecha}`, 5.8, 0.8);
  pdf.text(`Factura: ${factura.id}`, 5.8, 1);
  pdf.text(`Número autorización: ${factura.numeroAutorizacion}`, 5.8, 1.2);
  pdf.text(`Médico: ${firmaDoctor()}`, 5.8, 1.4);

  pdf.setFontSize(16);
  pdf.setLineWidth(0.02).line(marginLeft, 1.5, 8, 1.5);

  pdf.setDrawColor(0, 0, 0);

  var finalY = pdf.lastAutoTable.finalY || 0.2;
  pdf.autoTable({
    theme: tableBodyTheme,
    headStyles: tableHeadStyles,
    startX: marginLeft,
    startY: 1.8,
    columns: [
      { header: "Código", dataKey: "id" },
      { header: "Descripcion", dataKey: "descripcion" },
      { header: "Cantidad", dataKey: "cantidad" },
      { header: "Precio", dataKey: "precio" },
      { header: "Cobertura", dataKey: "cobertura" },
      { header: "Total", dataKey: "total" },
    ],
    body: factura.articulos,
    margin: { left: marginLeft, top: 0.2 },
  });

  finalY = pdf.lastAutoTable.finalY + 0.2;

  //TOTALES
  //LADOS IZQUIERDO
  pdf.setFontSize(11);
  pdf.setFont("Helvetica", "bold");
  pdf.text(`Sub Total: `, 6.5, finalY + 0.4);
  pdf.text(`ITBIS: `, 6.5, finalY + 0.6);
  pdf.text(`Cobertura: `, 6.5, finalY + 0.8);
  pdf.text(`Descuento: `, 6.5, finalY + 1);
  pdf.text(`Diferencia: `, 6.5, finalY + 1.2);
  pdf.text(`Total: `, 6.5, finalY + 1.4);

  //LADO DERECHO
  pdf.setFont("Helvetica", "normal");
  pdf.text(`$${factura.subTotal}`, 7.8, finalY + 0.4, alignRight);
  pdf.text(`$${factura.totalItbis}`, 7.8, finalY + 0.6, alignRight);
  pdf.text(`$${factura.totalCobertura}`, 7.8, finalY + 0.8, alignRight);
  pdf.text(`$${factura.totalDescuento}`, 7.8, finalY + 1, alignRight);
  pdf.text(`$${factura.totalDiferencia}`, 7.8, finalY + 1.2, alignRight);
  pdf.text(`$${factura.total}`, 7.8, finalY + 1.4, alignRight);

  pdf.setFontSize(11);
  pdf.setDrawColor(0, 0, 0);

  const lineMargin = localizacionPiePagina - 0.6;
  pdf
    .setLineWidth(0.02)
    .line(1.5, lineMargin, 4, lineMargin, null, null, alignLeft);

  pdf.text(
    firmaDoctor(),
    2 - marginLeft,
    localizacionPiePagina - 0.4,
    null,
    null,
    alignLeft
  );

  pdf
    .setLineWidth(0.02)
    .line(7.3, lineMargin, 4, lineMargin, null, null, alignRight);

  pdf.text(
    "Recibido conforme",
    7 - marginLeft,
    localizacionPiePagina - 0.4,
    null,
    null,
    alignCenter
  );

  setPiePagina(pdf);

  pdf.autoPrint();
  window.open(pdf.output("bloburl"), "_blank");
}

//UTILIDADES
function setPiePagina(pdf) {
  pdf.setFont("helvetica", "bold");
  //PIE DE PAGINA
  const pageCount = pdf.internal.getNumberOfPages();
  pdf.setFontSize(9);
  for (let index = 1; index <= pageCount; index++) {
    pdf.setPage(index);
    pdf.text(
      "Royaltrix Software Company",
      marginLeft,
      localizacionPiePagina,
      null,
      null,
      "left"
    );
    pdf.text(
      "Pág. " + String(index) + " de " + String(pageCount),
      8.2 - marginLeft,
      localizacionPiePagina,
      null,
      null,
      "right"
    );
  }
}

function setFirmaDoctor(pdf) {
  pdf.setFontSize(10);
  pdf.text(
    firmaDoctor(),
    4.8 - marginLeft,
    localizacionPiePagina - 0.2,
    null,
    null,
    alignCenter
  );

  const lineMargin = localizacionPiePagina - 0.4;
  pdf.setDrawColor(0, 0, 0);
  pdf
    .setLineWidth(0.02)
    .line(6.2 - marginLeft, lineMargin, 3, lineMargin, null, null, alignCenter);
}

//DATOS DE LOS REPORTES DE INDICACIONES
const isLandscape = () => (getConfiguracionReporte().landscape ? true : false);
const tipoPapelIndicacion = () => (isLandscape() ? "landscape" : "portrait");
const indicacionMarginLeft = () => (isLandscape() ? 4.5 : 0.3);
const tamanoPaginaIndicacion = [8.5, 5.5];

function datosPaciente(pdf, nombrePaciente) {
  pdf.setFont("times", "bold");
  pdf.setFontSize(10);
  pdf.text(`${nombrePaciente}`, indicacionMarginLeft(), 2.3);
}

//PORTRAIT
function imprimirMedicamentosIndicados(
  medicamentos,
  nombrePaciente,
  observacion
) {
  let pdf = new jsPDF(tipoPapelIndicacion(), "in", tamanoPaginaIndicacion);

  datosPaciente(pdf, nombrePaciente);

  pdf.autoTable({
    theme: tableIndicacionBodyTheme,
    headStyles: tableHeadStyles,
    bodyStyles: tableBodyStyles,
    startX: indicacionMarginLeft(),
    tableWidth: "auto",
    startY: 2.5,
    columns: [
      { header: "", dataKey: "descripcion" },
      { header: "Dosis", dataKey: "dosis" },
      { header: "Vía", dataKey: "via" },
      { header: "Uso", dataKey: "uso" },
    ],
    body: medicamentos,
    margin: { left: indicacionMarginLeft(), top: 0.01 },
  });

  setObservacionIndicacion(pdf, observacion);
  print(pdf);
}
function imprimir(url, params, title) {
  axios({
    url: url,
    method: "POST",
    contentType: "application/json",
    responseType: "blob",
    data: params,
  }).then((response) => {
    let d = new Date();
    let n = d.toLocaleTimeString();
    let fileName = `${title}${n}.pdf`;
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }).catch(error => console.error(error));
}

function imprimirFormularioPersonalizado(items, nombrePaciente) {
  let pdf = new jsPDF(tipoPapelIndicacion(), "in", tamanoPaginaIndicacion);

  let ejeY = 2;
  pdf.setFont("arial", "bold");
  pdf.setFontSize(10);
  pdf.text(`${nombrePaciente}`, marginLeft, ejeY);
  ejeY = 2.5;
  for (let x = 0; x < items.length; x++) {
    pdf.setFont("arial", "bold");
    pdf.setFontSize(10);
    pdf.text(items[x].nombre, marginLeft, ejeY);

    for (let y = 0; y < items[x].subItems.length; y++) {
      pdf.setFont("arial", "regular");
      pdf.text(items[x].subItems[y].nombre, marginLeft + 0.2, ejeY + 0.4);
      pdf.text(items[x].subItems[y].valor, marginLeft + 1.5, ejeY + 0.4);
      ejeY += 0.3;
    }
    ejeY += 0.4;
  }

  print(pdf);
}
function imprimirLaboratoriosIndicados(
  laboratorios,
  nombrePaciente,
  observacion
) {
  let pdf = new jsPDF(tipoPapelIndicacion(), "in", tamanoPaginaIndicacion);

  datosPaciente(pdf, nombrePaciente);

  pdf.autoTable({
    theme: tableIndicacionBodyTheme,
    headStyles: tableHeadStyles,
    bodyStyles: tableBodyStyles,
    startX: indicacionMarginLeft(),
    startY: 2.5,
    columns: [{ header: "", dataKey: "descripcion" }],
    body: laboratorios,
    margin: { left: indicacionMarginLeft(), top: 0.01 },
  });

  setObservacionIndicacion(pdf, observacion);
  print(pdf);
}
function imprimirImagenesIndicadas(imagenes, nombrePaciente, observacion) {
  let pdf = new jsPDF(tipoPapelIndicacion(), "in", tamanoPaginaIndicacion);

  datosPaciente(pdf, nombrePaciente);

  pdf.autoTable({
    theme: tableIndicacionBodyTheme,
    headStyles: tableHeadStyles,
    bodyStyles: tableBodyStyles,
    startX: indicacionMarginLeft(),
    startY: 2.5,
    columns: [{ header: "", dataKey: "descripcion" }],
    body: imagenes,
    margin: { left: indicacionMarginLeft(), top: 0.01 },
  });

  setObservacionIndicacion(pdf, observacion);
  print(pdf);
}

function print(pdf) {
  pdf.autoPrint();
  window.open(pdf.output("bloburl"), "_blank");
}

function setFecha(pdf) {
  pdf.setFontSize(9);
  pdf.text(`Fecha: ${fecha}`, 8, 0.2, null, null, alignRight);
}

function setObservacionIndicacion(pdf, observacion) {
  var finalY = pdf.lastAutoTable.finalY || 1.3;
  pdf.setFontSize(10);
  pdf.text(
    indicacionMarginLeft(),
    finalY + 0.4,
    pdf.splitTextToSize(
      `Observación: ${observacion}`,
      isLandscape() ? 3.5 : 4.5
    )
  );
}

export {
  imprimir,
  imprimirHistoriaClinica,
  imprimirFactura,
  imprimirMedicamentosIndicados,
  imprimirLaboratoriosIndicados,
  imprimirImagenesIndicadas,
  imprimirFormularioPersonalizado,
};
