<template>
  <v-container id="listado-pacientesCitados-citados" m-0 p-0>
    <h1>Pacientes Citados</h1>

    <v-layout>
      <RegistroCita
        :dialogRegistroCitas.sync="dialogRegistroCitas"
        :paciente="paciente"
        :camposPacienteDeshabilitados="camposPacienteDeshabilitados"
        @cerrarModalCitas="cerrarModalCitas"
      ></RegistroCita>
    </v-layout>
    <!--FILTRO-->
    <v-text-field
      v-model="busqueda"
      dense
      outlined
      label="Buscar"
      :append-icon="iconoCrear"
      @keyup.enter="onBuscarPaciente()"
      @click:append="mostrarModalCita()"
    ></v-text-field>

    <!--FECHAS-->
    <v-layout>
      <v-flex sm12>
        <v-menu
          ref="menuFechaDesde"
          v-model="menuFechaDesde"
          :return-value.sync="fechaDesde"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="fechaDesde"
              label="Fecha desde"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker v-model="fechaDesde" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menuFechaDesde = false"
              >Cancelar</v-btn
            >
            <v-btn
              text
              color="primary"
              @click="$refs.menuFechaDesde.save(fechaDesde)"
              >OK</v-btn
            >
          </v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex sm12 ml-2>
        <v-menu
          ref="menuFechaHasta"
          v-model="menuFechaHasta"
          :return-value.sync="fechaHasta"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="fechaHasta"
              label="Fecha hasta"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker v-model="fechaHasta" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menuFechaHasta = false"
              >Cancelar</v-btn
            >
            <v-btn
              text
              color="primary"
              @click="$refs.menuFechaHasta.save(fechaHasta)"
              >OK</v-btn
            >
          </v-date-picker>
        </v-menu>
      </v-flex>

      <!-- <v-flex md2 sm12 ml-2>
        <v-btn
          outlined
          rounded
          color="primary"
          @click="getpacientesCitadosCitados()"
        >
          <v-icon color="primary"> mdi-magnify </v-icon>
        </v-btn>
      </v-flex> -->
    </v-layout>

    <v-layout>
      <v-combobox
        v-model="estadoCitaSeleccionada"
        :items="estadosCitas"
        label="Filtrar turno por"
      ></v-combobox>
    </v-layout>

    <v-layout v-if="pacientesCitados.length <= 0" class="text-center">
      <v-alert type="info" dense outlined>{{ mensajeDatos }}</v-alert>
    </v-layout>

    <v-layout v-else>
      <v-container>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(item, index) in pacientesCitadosFiltradosporCitas"
            :key="index"
          >
            <v-expansion-panel-header>
              <v-layout row>
                <v-flex md6>
                  <h4
                    :class="{
                      colorResaltado: item.paciente.resaltado === true,
                    }"
                  >
                    {{ item.paciente.nombreCompleto }}
                  </h4>
                </v-flex>
                <v-flex md3>
                  <h4
                    :class="{
                      colorResaltado: item.paciente.resaltado === true,
                    }"
                  >
                    {{ item.fecha | fechaFilter }}
                  </h4>
                </v-flex>
                <v-flex md3 justify-end>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-1"
                        small
                        rounded
                        outlined
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="asignarTurno(item)"
                      >
                        <v-icon color="primary"> mdi-ticket</v-icon>
                      </v-btn></template
                    >
                    <span>Asignar Turno</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-1"
                        small
                        rounded
                        outlined
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.estado === 'PENDIENTE'"
                        @click="recordar(item)"
                      >
                        <v-icon color="primary"> mdi-bell</v-icon>
                      </v-btn></template
                    >
                    <span>Recordar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-1"
                        small
                        rounded
                        outlined
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="crearHistoriaClinica(item)"
                      >
                        <v-icon color="primary"> mdi-doctor</v-icon>
                      </v-btn></template
                    >
                    <span>Realizar Consulta</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-2"
                        small
                        rounded
                        outlined
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.estado === 'PENDIENTE' || puedeConsultar"
                        @click="eliminarCita(item)"
                      >
                        <v-icon color="error"> mdi-close</v-icon>
                      </v-btn></template
                    >
                    <span>Eliminar Turno</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-container>
                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Cédula</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.paciente.cedula }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Sexo</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">{{ item.paciente.sexo }}</p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Seguro Médico</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      <!-- {{ item.seguroMedico.paciente.descripcion }} -->
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">No. Afiliado</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.paciente.numeroAfiliado }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Fecha Nacimiento</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.paciente.fechaNacimiento }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Edad</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.paciente.edad }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Celular</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.paciente.celular }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Teléfono</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.paciente.telefono }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Dirección</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.paciente.direccion }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Correo Electrónico</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.paciente.correoElectronico }}
                    </p>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-footer
          ><p>Total {{ pacientesCitadosFiltradosporCitas.length }}</p></v-footer
        >
      </v-container>
    </v-layout>

    <ConsultasAnteriores
      :paciente="paciente"
      :dialogConsultasAnteriores="dialogConsultasAnteriores"
      :historiasClinicas="historiasClinicas"
      @cerrarModalConsultasAnteriores="cerrarModalConsultasAnteriores"
      @onHistoriaClinicaCancelada="onHistoriaClinicaCancelada"
    ></ConsultasAnteriores>

    <overlay :overlay="overlay"></overlay>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { Rol } from "@/utility/roles.js";
import { dateNow } from "@/utility/dateHelpers";
import axios from "@/shared/jwtAxios.js";
import Overlay from "../helpers/Overlay.vue";
import ConsultasAnteriores from "@/components/historiasClinicasGroup/ConsultasAnteriores.vue";
import MainLayout from "@/layouts/MainLayout";
import moment from "moment";
import store from "@/state/index.js";
import jwtAxios from "@/shared/jwtAxios.js";
import RegistroCita from "@/components/cita/RegistroCita.vue";

export default {
  name: "listado-pacientesCitados-citados",
  components: {
    Overlay,
    ConsultasAnteriores,
    MainLayout,
    RegistroCita,
  },
  props: {
    esRegistrar: Boolean,
    itemsPorPagina: Number,
    encabezados: Array,
  },
  created: function () {
    this.overlay = true;
  },
  mounted() {
    this.$emit("update:layout", MainLayout);
    this.puedeConsultar = Rol.Secretaria !== this.$store.getters.rolUsuario;
    this.getpacientesCitadosCitados();
  },
  data() {
    return {
      overlay: false,
      url: this.$apiConfig.citaUrl,
      historiaClinicaUrl: this.$apiConfig.historiasClinicasUrl,
      turnosUrl: this.$apiConfig.turnosUrl,
      benched: 0, //PROPIEDAD UTILIZADA PARA EL V-VIRTUAL-SCROLL. FUNCIONA PARA MEJORAR EL PERFORMANCE DE DICHO CONTROL.
      busqueda: "",
      camposPacienteDeshabilitados: false,
      iconoCrear: "mdi-plus",
      puedeConsultar: true,
      dialogConsultasAnteriores: false,
      pacientesCitados: [],
      paciente: {},
      dialogHistoriaClinica: false,
      dialogRegistroCitas: false,
      historiasClinicas: [],
      estadosCitas: ["PENDIENTE", "CONFIRMADA", "REALIZADA", "CANCELADA"],
      estadoCitaSeleccionada: "PENDIENTE",
      historiaClinica: {
        motivoConsulta: "",
        historiaEnfermedad: "",
        antecedentesPatologicos: "",
        antecedentesNoPatologicos: "",
        antecedentesQuirurgicos: "",
        antecedentesFamiliares: "",
        antecedentesGinecologicos: "",
        antecedentesOtros: "",
        sonografias: "",
        radiografias: "",
        laboratorios: "",
        otrosEstudios: "",
        peso: "",
        talla: "",
        temperatura: "",
        tensionArterial: "",
        frecuenciaRespiratoria: "",
        inspeccionGeneral: "",
        medicamentosIndicados: [],
        laboratoriosIndicados: [],
        imagenesIndicadas: [],
      },

      fechaDesde: dateNow(),
      menuFechaDesde: false,
      fechaHasta: dateNow(),
      menuFechaHasta: false,
      mensajeDatos: "Cargando datos...",
    };
  },
  computed: {
    rows() {
      return this.pacientesCitados !== null && this.pacientesCitados.length;
    },
    pacientesCitadosFiltradosporCitas() {
      return this.pacientesCitados.filter((paciente) => {
        return paciente.estado === this.estadoCitaSeleccionada &&
            (paciente.nombreCompleto
                    .toUpperCase()
                    .includes(this.busqueda.toUpperCase()) ||
                paciente.cedula.includes(this.busqueda.trim()));
      });
    },

    pacienteItemHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
        case "md":
        case "lg":
        case "xl":
          return 100;
      }
    },

    actionsButtonsWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return null;
        case "sm":
          return null;
        case "md":
          return 100;
        case "lg":
          return 100;
        case "xl":
          return 100;
      }
    },

    toggleActionsButtonsText() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return true;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
  },
  methods: {
    asignarTurno(pacienteParam) {
      window.console.log(pacienteParam);

      let turnoPacienteRequest = {
        cuentaId: store.state.cuentaId,
        pacienteId: pacienteParam.pacienteId,
      };

      axios
        .post(this.turnosUrl, turnoPacienteRequest)
        .then(() => {
          swal({
            title: "Información",
            text: "Turno asignado correctamente.",
            icon: "success",
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            swal({
              title: "Aviso",
              text: error.response.data,
              icon: "warning",
            });
          } else {
            window.console.log(error.message);
          }
        });
    },
    getpacientesCitadosCitados() {
      axios
        .get(this.url + "/getByRangoFecha/", {
          params: {
            medicoId: this.$store.state.medicoId,
            fechaDesde: this.fechaDesde,
            fechaHasta: this.fechaHasta,
          },
        })
        .then((response) => {
          this.pacientesCitados = response.data;
          // window.console.log(this.pacientesCitados);
          //this.$forceUpdate();
          this.overlay = false;
          if (
            this.pacientesCitados !== null &&
            this.pacientesCitados !== undefined
          ) {
            this.mensajeDatos = "No hay datos disponibles.";
          }
        })
        .catch((error) => {
          this.overlay = false;
          window.console.log(error.message);
        });
    },

    getConsultasAnteriores() {
      axios
        .get(this.historiaClinicaUrl + "/getByPaciente/" + this.paciente.id)
        .then((response) => {
          this.historiasClinicas = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    cerrarModalCitas() {
      this.dialogRegistroCitas = false;

      this.getpacientesCitadosCitados();
    },
    mostrarModalCita() {
      this.paciente = {
        nombre: "",
        apellidos: "",
      };
      this.camposPacienteDeshabilitados = false;
      this.dialogRegistroCitas = true;
    },

    crearPaciente() {
      this.$emit("crearPaciente", true);
    },

    crearHistoriaClinica(pacienteParam) {
      this.paciente = pacienteParam;
      this.getConsultasAnteriores();
      this.mostrarModalConsultasAnteriores();
    },

    async recordar(cita) {
      let response = await jwtAxios.post(
        this.$apiConfig.citaUrl + "/recordar/" + cita.id
      );
      if (response.status === 200) {
        this.$success(response.data);
      }
    },
    eliminarCita(citaParam) {
      window.console.log(citaParam);
      swal({
        text: "¿Desea cancelar esta cita?",
        icon: "warning",
        buttons: {
          confirm: {
            text: "Si",
            value: true,
            closeModal: true,
          },
          cancel: {
            text: "No",
            value: null,
            visible: true,
            closeModal: true,
          },
        },
      }).then((quereEliminar) => {
        if (quereEliminar) {
          axios
            .post(`${this.url}/eliminarCita/` + citaParam.id)
            .then(() => {
              this.$datosGuardados();
              this.getpacientesCitadosCitados();
            })
            .catch((error) => {
              this.$errorGuardarDatos();
              window.console.log(error);
            });
        }
      });
    },
    editarPaciente(pacienteParam) {
      this.$emit("editarPaciente", pacienteParam);
    },
    eliminarPaciente(pacienteParam) {
      swal({
        text: "¿Desea eliminar este paciente?",
        icon: "warning",
        buttons: {
          confirm: {
            text: "Si",
            value: true,
            closeModal: true,
          },
          cancel: {
            text: "No",
            value: null,
            visible: true,
            closeModal: true,
          },
        },
      }).then((quereEliminar) => {
        if (quereEliminar) {
          pacienteParam.activo = false;
          axios
            .post(this.$apiConfig.pacientesCitadosUrl, pacienteParam)
            .then(() => {
              this.$emit("onPacienteEliminado");
              this.$datosGuardados();
            })
            .catch((error) => {
              window.console.log(error);
            });
        }
      });
    },
    registrarCita(pacienteParam) {
      this.$emit("registrarCita", pacienteParam);
    },
    seleccionarPaciente(pacienteParam) {
      this.$emit("seleccionarPaciente", pacienteParam);
    },
    onBuscarPaciente() {
      this.$emit("onBuscarPaciente", this.busqueda);
    },
    cerrarModalConsultasAnteriores() {
      this.dialogConsultasAnteriores = false;
      this.getpacientesCitadosCitados();
    },
    mostrarModalConsultasAnteriores() {
      this.dialogConsultasAnteriores = true;
    },
    mostrarModalHistoriaClinica() {
      this.dialogHistoriaClinica = true;
      this.toggleHistoriaClinicaKey();
    },
    onHistoriaClinicaCancelada() {
      this.getConsultasAnteriores();
    },
    cerrarModalHistoriaClinica() {
      this.dialogHistoriaClinica = false;
      this.toggleHistoriaClinicaKey();
      this.getpacientesCitadosCitados();
    },
  },

  filters: {
    fechaFilter: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
    horaFilter: function (date) {
      return moment(date).format("h:mm a");
    },
  },
  watch: {
    fechaDesde: function (newValue) {
      if (newValue !== null) {
        this.getpacientesCitadosCitados();
      }
    },
    fechaHasta: function (newValue) {
      if (newValue !== null) {
        this.getpacientesCitadosCitados();
      }
    },
  },
};
</script>

<style scoped v-lang="scss">
h1,
h2,
h3,
h4 {
  color: var(-v-primary-base);
}

#titulo-pacientesCitados-cargados {
  margin-bottom: 30px;
}

.pacientesCitados-container {
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 10px;
}

.action-button {
  color: #1c7494;
  border: 1px solid #1c7494;
  padding: 6px 8px;
  margin: 0px 1px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: bold;
}

.action-button:hover {
  background-color: rgba(28, 116, 148, 0.05);
}

.colorResaltado {
  color: #c52121;
}

@media screen and (min-width: 640px) {
  .action-button {
    width: 80px;
    margin: 0px 20px 0px 0px;
  }
}
</style>
