<template>
    <div>
        <v-container id="imagenes-hc">
            <v-row>
                <v-col cols="4">
                    <v-menu
                            ref="menuFecha"
                            v-model="menuFecha"
                            :return-value.sync="fecha"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    dense
                                    outlined
                                    v-model="fecha"
                                    label="Fecha"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                            ></v-text-field>
                        </template>

                        <v-date-picker
                                @click="menuFecha = false"
                                @input="$refs.menuFecha.save(fecha)"
                                v-model="fecha"
                                no-title
                                scrollable
                        >
                            <!-- <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menuFecha = false"
                            >Cancelar</v-btn
                          >
                          <v-btn text color="primary" @click="$refs.menuFecha.save(fecha)"
                            >OK</v-btn
                          > -->
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="12px">
                    <v-combobox
                            outlined
                            dense
                            v-model="imagenesSeleccionadas"
                            :filter="filtrarImagenes"
                            :hide-no-data="!searchImagen"
                            :items="imagenes"
                            :search-input.sync="searchImagen"
                            class="upperCase"
                            hide-selected
                            label="Seleccione una o varias imágenes"
                            multiple
                            small-chips
                            @keydown.enter="guardarImagen(0, searchImagen)"
                    >
                        <template v-slot:no-data>
                            <v-list-item>
              <span class="subheading">
                No se encontró
                <v-chip label small>{{ searchImagen }}</v-chip
                >presione <strong>ENTER</strong> para crear la imágen
              </span>
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                            <v-chip
                                    v-if="item === Object(item)"
                                    v-bind="attrs"
                                    :input-value="selected"
                                    label
                                    small
                            >
                                <span class="pr-2">{{ item.text }}</span>
                                <v-icon small @click="parent.selectItem(item)">close</v-icon>
                            </v-chip>
                        </template>
                        <template v-slot:item="{ index, item }">
                            <v-text-field
                                    outlined
                                    dense
                                    v-if="editing === item"
                                    v-model="editing.text"
                                    autofocus
                                    flat
                                    class="upperCase"
                                    background-color="transparent"
                                    hide-details
                                    @keyup.enter="editarImagen(index, item)"
                            ></v-text-field>
                            <v-chip v-else label small>{{ item.text }}</v-chip>
                            <v-spacer></v-spacer>
                            <v-list-item-action @click.stop>
                                <v-btn icon @click.stop.prevent="editarImagen(index, item)">
                                    <v-icon>
                                        {{ editing !== item ? "mdi-pencil" : "mdi-check" }}
                                    </v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </template>
                    </v-combobox>

                    <v-btn
                            class="mb-5"
                            @click="indicarDesdePlantilla()"
                            text
                            rounded
                            color="primary"
                    >
                        <v-icon left>mdi-vector-selection</v-icon>Indicar desde plantilla
                    </v-btn>

                    <v-text-field
                            id="txtObservacion"
                            dense
                            outlined
                            v-model="observacion"
                            label="Observación (opcional)"
                    ></v-text-field>

                    <v-row v-if="imagenesSeleccionadas.length > 0" class="text-right">
                        <v-col cols="12">
                            <v-btn @click="imprimirIndicacion()" text rounded color="primary">
                                <v-icon left>mdi-printer</v-icon>Imprimir
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-btn @click="guardarComoPlantilla()" text rounded color="primary">
                                <v-icon left>mdi-check</v-icon>Guardar como plantilla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>

                <registro-plantilla
                        :key="plantillakey"
                        v-on:cerrarModal="cerrarModalPlantilla()"
                        v-on:onPlantillaGuardada="reRenderListadoPlantillas"
                        :contexto="contexto"
                        :dialog.sync="dialogPlantilla"
                ></registro-plantilla>

                <listado-plantillas
                        :key="plantillaskey"
                        :dialog.sync="dialogPlantillas"
                        :contexto="contexto"
                        v-on:onItemSeleccionado="onPlantillaSeleccionada"
                        v-on:cerrarModal="cerrarModalPlantillas()"
                ></listado-plantillas>
            </v-row>
        </v-container>
        <loading-dialog :dialog="dialogImpresion" :mensaje="mensajeImpresion"></loading-dialog>
    </div>
 
</template>

<script>
import jwtAxios from "../../../shared/jwtAxios.js";
import { imprimir } from "../../../utility/reporting.js";
import { contextoPlantillaIndicacion } from "../../../utility/enumerations.js";
import registroPlantilla from "../indicaciones/plantillas/registroplantilla.vue";
import listadoPlantillas from "../indicaciones/plantillas/listadoplantillas.vue";
import { dateNow, removeTimeFromDate } from "../../../utility/dateHelpers.js";
import LoadingDialog from "../../dialogs/LoadingDialog.vue";

export default {
  name: "imagenes-hc",
  components: {
      LoadingDialog,
    registroPlantilla,
    listadoPlantillas,
  },
  props: {
    fechaIndicacionImagenes: "",
    observacionIndicacionImagenes: "",
    nombrePaciente: "",
    historiaClinicaId: String,
  },
  mounted() {
    this.getImagenes();
    this.getImagenesByHistoriaId();
  },
  data() {
    return {
      plantillakey: 0,
      plantillaskey: 50,
      contexto: "",
      dialogPlantilla: false,
      dialogPlantillas: false,
      on: null,
      imagenesUrl: this.$apiConfig.imagenesUrl,
      imagen: {
        id: 0,
        cuentaId: 0,
        descripcion: "",
        text: "",
        activo: true,
      },
      observacion: "",
      editing: null,
      editingIndex: -1,
      nonce: 1,
      searchImagen: "",
      imagenes: [],
      imagenesSeleccionadas: [],
      fecha: dateNow(),
      menuFecha: false,
        dialogImpresion: false,
        mensajeImpresion: "Imprimiendo..."
    };
  },
  methods: {
    //IMAGENES
    editarImagen(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
        item.text = item.text.toUpperCase();
        this.guardarImagen(item.id, item.text);
      }
    },
    filtrarImagenes(item, queryText, itemText) {
      if (item.header) return false;
      const hasValue = (val) => (val != null ? val : "");
      const descripcion = hasValue(itemText);
      const query = hasValue(queryText);
      return (
        descripcion
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
    getImagenes() {
      jwtAxios
        .get(`${this.imagenesUrl}/${this.$store.state.cuentaId}`)
        .then(({ data }) => {
          this.imagenes = data;
        })
        .catch((error) => {
          window.console.log(error);
          this.$errorCargarDatos();
        });
    },
    getImagenesByHistoriaId() {
      jwtAxios
        .get(
          `${this.$apiConfig.imagenesUrl}/Ihistoria/${this.historiaClinicaId}`
        )
        .then((response) => {
          this.imagenesSeleccionadas = response.data;
          this.$store.commit("setImagenes", response.data);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    insertar() {
      jwtAxios
        .post(this.imagenesUrl, this.imagen)
        .then(() => {
          this.$datosGuardados();
          this.getImagenes();
        })
        .catch((error) => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    actualizar() {
      jwtAxios
        .put(this.imagenesUrl, this.imagen)
        .then(() => {
          this.$datosGuardados();
          this.getImagenes();
        })
        .catch((error) => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    guardarImagen(_id, _descripcion) {
      this.imagen.id = _id;
      (this.imagen.cuentaId = Number.parseInt(this.$store.state.cuentaId)),
        (this.imagen.descripcion = _descripcion.toUpperCase());
      if (_id == 0) this.insertar();
      else this.actualizar();
    },
    imprimirIndicacion() {
        this.dialogImpresion = true;
      let array = JSON.stringify(this.imagenesSeleccionadas);
      let parametros = {
        nombrePaciente: this.nombrePaciente,
        fecha: this.fecha,
        observacion: this.observacion,
        nombreReporte: this.$store.state.reporteImagenes,
        array: array,
      };

        setTimeout(() => {
            this.dialogImpresion = false;
        }, 3000)

      imprimir(`${this.imagenesUrl}/imprimir/`, parametros, this.nombrePaciente);
    },
    reRenderRegistroPlantilla() {
      this.plantillakey += 65;
    },
    reRenderListadoPlantillas() {
      this.plantillaskey += 85;
    },
    guardarComoPlantilla() {
      this.dialogPlantilla = true;
      this.contexto = contextoPlantillaIndicacion.IMAGEN;
    },
    cerrarModalPlantilla() {
      this.dialogPlantilla = false;
      this.contexto = "";
      this.reRenderRegistroPlantilla();
    },
    cerrarModalPlantillas() {
      this.dialogPlantillas = false;
      this.contexto = "";
    },
    indicarDesdePlantilla() {
      this.dialogPlantillas = true;
      this.contexto = contextoPlantillaIndicacion.IMAGEN;
    },
    onPlantillaSeleccionada(plantilla) {
      this.imagenesSeleccionadas = plantilla.detalle;
    },
  },
  watch: {
    imagenesSeleccionadas: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$store.commit("setImagenes", newValue);
      },
    },
    fecha: function (newValue) {
      this.$store.commit("setFechaIndicacionImagenes", newValue);
    },
    fechaIndicacionImagenes: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== null && newValue !== undefined) {
          this.fecha = removeTimeFromDate(newValue) || dateNow();
        }
      },
    },
    observacion: function (newValue) {
      this.$store.commit("setObservacionIndicacionImagenes", newValue);
    },
    observacionIndicacionImagenes: {
      immediate: true,
      handler(newValue) {
        this.observacion = newValue;
      },
    },
  },
};
</script>
