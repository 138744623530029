import { render, staticRenderFns } from "./PacientesEnTurno.vue?vue&type=template&id=820ba3a4&scoped=true&"
import script from "./PacientesEnTurno.vue?vue&type=script&lang=js&"
export * from "./PacientesEnTurno.vue?vue&type=script&lang=js&"
import style0 from "./PacientesEnTurno.vue?vue&type=style&index=0&id=820ba3a4&scoped=true&v-lang=scss&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "820ba3a4",
  null
  
)

export default component.exports