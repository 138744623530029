<template>
  <v-container id="listado-pacientes" m-0 p-0>
    <h1>Pacientes en Turno</h1>

    <!--FILTRO-->
    <v-text-field
      v-model="busqueda"
      dense
      outlined
      label="Buscar"
      :append-icon="iconoCrear"
      @keyup.enter="onBuscarPaciente()"
      @click:append="crearPaciente()"
    ></v-text-field>

    <!--FECHAS-->
    <v-layout>
      <v-row>
        <v-col>
          <v-menu
            ref="menuFechaDesde"
            v-model="menuFechaDesde"
            :return-value.sync="fechaDesde"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="fechaDesde"
                label="Fecha desde"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker v-model="fechaDesde" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuFechaDesde = false"
                >Cancelar</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="$refs.menuFechaDesde.save(fechaDesde)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col>
          <v-menu
            ref="menuFechaHasta"
            v-model="menuFechaHasta"
            :return-value.sync="fechaHasta"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="fechaHasta"
                label="Fecha hasta"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker v-model="fechaHasta" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuFechaHasta = false"
                >Cancelar</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="$refs.menuFechaHasta.save(fechaHasta)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-layout>

    <v-layout>
      <v-combobox
        v-model="estadoTurnoSeleccionado"
        :items="estadosTurnos"
        label="Filtrar turno por"
      ></v-combobox>
    </v-layout>

    <v-layout v-if="pacientes.length <= 0" class="text-center">
      <v-alert type="info" dense outlined>{{ mensajeDatos }}</v-alert>
    </v-layout>

    <v-layout v-else>
      <v-container>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(item, index) in pacientesFiltradosporTurnos"
            :key="index"
          >
            <v-expansion-panel-header>
              <v-layout row>
                <v-flex md9>
                  <h4 :class="{ colorResaltado: item.resaltado === true }">
                    {{ item.nombreCompleto }}
                  </h4>
                </v-flex>
                <v-flex md3 justify-end>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-1"
                        small
                        rounded
                        outlined
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.turnoEstado === 'PENDIENTE'"
                        @click="atenderTurno(item)"
                      >
                        <v-icon color="primary"> mdi-check</v-icon>
                      </v-btn></template
                    >
                    <span>Atender</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-1"
                        small
                        rounded
                        outlined
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="crearHistoriaClinica(item)"
                      >
                        <v-icon color="primary"> mdi-doctor</v-icon>
                      </v-btn></template
                    >
                    <span>Realizar Consulta</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-2"
                        small
                        rounded
                        outlined
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        v-if="
                          item.turnoEstado === 'PENDIENTE' || puedeConsultar
                        "
                        @click="eliminarTurno(item)"
                      >
                        <v-icon color="error"> mdi-close</v-icon>
                      </v-btn></template
                    >
                    <span>Eliminar Turno</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-container>
                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Cédula</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">{{ item.cedula }}</p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Sexo</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">{{ item.sexo }}</p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Seguro Médico</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.seguroMedico.descripcion }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">No. Afiliado</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.numeroAfiliado }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Fecha Nacimiento</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.fechaNacimiento }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Edad</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.edad }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Celular</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.celular }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Teléfono</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.telefono }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Dirección</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.direccion }}
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row no-gutters>
                  <v-flex xs12 md3
                    ><p class="font-weight-black">Correo Electrónico</p>
                  </v-flex>
                  <v-flex xs12 md9>
                    <p class="font-weight-regular">
                      {{ item.correoElectronico }}
                    </p>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-footer
          ><p>Total {{ pacientesFiltradosporTurnos.length }}</p></v-footer
        >
      </v-container>
    </v-layout>

    <ConsultasAnteriores
      :paciente="paciente"
      :dialogConsultasAnteriores="dialogConsultasAnteriores"
      :historiasClinicas="historiasClinicas"
      @cerrarModalConsultasAnteriores="cerrarModalConsultasAnteriores"
      @onHistoriaClinicaCancelada="onHistoriaClinicaCancelada"
    ></ConsultasAnteriores>

    <overlay :overlay="overlay"></overlay>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { Rol } from "@/utility/roles.js";
import { dateNow } from "@/utility/dateHelpers";
import axios from "@/shared/jwtAxios.js";
import Overlay from "../helpers/Overlay.vue";
import ConsultasAnteriores from "@/components/historiasClinicasGroup/ConsultasAnteriores.vue";
import MainLayout from "@/layouts/MainLayout";

export default {
  name: "listado-pacientes",
  components: {
    Overlay,
    ConsultasAnteriores,
    MainLayout,
  },
  props: {
    esRegistrar: Boolean,
    itemsPorPagina: Number,
    encabezados: Array,
  },
  created: function () {
    this.overlay = true;
  },
  mounted() {
    this.$emit("update:layout", MainLayout);
    this.puedeConsultar = Rol.Secretaria !== this.$store.getters.rolUsuario;
    this.getPacientesEnTurno();
  },
  data() {
    return {
      overlay: false,
      url: this.$apiConfig.turnosUrl,
      historiaClinicaUrl: this.$apiConfig.historiasClinicasUrl,
      benched: 0, //PROPIEDAD UTILIZADA PARA EL V-VIRTUAL-SCROLL. FUNCIONA PARA MEJORAR EL PERFORMANCE DE DICHO CONTROL.
      busqueda: "",
      iconoCrear: "mdi-plus",
      puedeConsultar: true,
      dialogConsultasAnteriores: false,
      pacientes: [],
      paciente: {},
      dialogHistoriaClinica: false,
      historiasClinicas: [],
      estadosTurnos: ["PENDIENTE", "ATENDIDO", "CANCELADO"],
      estadoTurnoSeleccionado: "PENDIENTE",
      historiaClinica: {
        motivoConsulta: "",
        historiaEnfermedad: "",
        antecedentesPatologicos: "",
        antecedentesNoPatologicos: "",
        antecedentesQuirurgicos: "",
        antecedentesFamiliares: "",
        antecedentesGinecologicos: "",
        antecedentesOtros: "",
        sonografias: "",
        radiografias: "",
        laboratorios: "",
        otrosEstudios: "",
        peso: "",
        talla: "",
        temperatura: "",
        tensionArterial: "",
        frecuenciaRespiratoria: "",
        inspeccionGeneral: "",
        medicamentosIndicados: [],
        laboratoriosIndicados: [],
        imagenesIndicadas: [],
      },

      fechaDesde: dateNow(),
      menuFechaDesde: false,
      fechaHasta: dateNow(),
      menuFechaHasta: false,
      mensajeDatos: "Cargando datos...",
    };
  },
  computed: {
    rows() {
      return this.pacientes !== null && this.pacientes.length;
    },
    pacientesFiltradosporTurnos() {
      return this.pacientes.filter((paciente) => {
        return paciente.turnoEstado === this.estadoTurnoSeleccionado &&
            (paciente.nombreCompleto
                .toUpperCase()
                .includes(this.busqueda.toUpperCase()) || 
                paciente.cedula.includes(this.busqueda.trim()));
      });
    },

    pacienteItemHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
        case "md":
        case "lg":
        case "xl":
          return 100;
      }
    },

    actionsButtonsWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return null;
        case "sm":
          return null;
        case "md":
          return 100;
        case "lg":
          return 100;
        case "xl":
          return 100;
      }
    },

    toggleActionsButtonsText() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return true;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
  },
  methods: {
    getPacientesEnTurno() {
      axios
        .get(this.url, {
          params: {
            cuentaId: this.$store.state.cuentaId,
            fechaDesde: this.fechaDesde,
            fechaHasta: this.fechaHasta,
          },
        })
        .then((response) => {
          this.pacientes = response.data;
          //this.$forceUpdate();
          this.overlay = false;
          if (this.pacientes !== null && this.pacientes !== undefined) {
            this.mensajeDatos = "No hay datos disponibles.";
          }
        })
        .catch((error) => {
          this.overlay = false;
          window.console.log(error.message);
        });
    },

    getConsultasAnteriores() {
      axios
        .get(this.historiaClinicaUrl + "/getByPaciente/" + this.paciente.id)
        .then((response) => {
          this.historiasClinicas = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },

    crearPaciente() {
      this.$emit("crearPaciente", true);
    },

    crearHistoriaClinica(pacienteParam) {
      this.paciente = pacienteParam;
      this.getConsultasAnteriores();
      this.mostrarModalConsultasAnteriores();
    },

    atenderTurno(pacienteParam) {
      swal({
        text: "¿Desea atender este turno?",
        icon: "warning",
        buttons: {
          confirm: {
            text: "Si",
            value: true,
            closeModal: true,
          },
          cancel: {
            text: "No",
            value: null,
            visible: true,
            closeModal: true,
          },
        },
      }).then((quiereAtender) => {
        if (quiereAtender) {
          let turnoPacienteRequest = {
            cuentaId: this.$store.state.cuentaId,
            pacienteId: pacienteParam.id,
          };
          axios
            .post(`${this.url}/atender`, turnoPacienteRequest)
            .then(() => {
              this.$datosGuardados();
              this.getPacientesEnTurno();
            })
            .catch((error) => {
              window.console.log(error);
            });
        }
      });
    },
    eliminarTurno(pacienteParam) {
      swal({
        text: "¿Desea eliminar este turno?",
        icon: "warning",
        buttons: {
          confirm: {
            text: "Si",
            value: true,
            closeModal: true,
          },
          cancel: {
            text: "No",
            value: null,
            visible: true,
            closeModal: true,
          },
        },
      }).then((quereEliminar) => {
        if (quereEliminar) {
          let turnoPacienteRequest = {
            cuentaId: this.$store.state.cuentaId,
            pacienteId: pacienteParam.id,
          };
          axios
            .post(`${this.url}/eliminar`, turnoPacienteRequest)
            .then(() => {
              this.$datosGuardados();
              this.getPacientesEnTurno();
            })
            .catch((error) => {
              this.$errorGuardarDatos();
              window.console.log(error);
            });
        }
      });
    },
    editarPaciente(pacienteParam) {
      this.$emit("editarPaciente", pacienteParam);
    },
    eliminarPaciente(pacienteParam) {
      swal({
        text: "¿Desea eliminar este paciente?",
        icon: "warning",
        buttons: {
          confirm: {
            text: "Si",
            value: true,
            closeModal: true,
          },
          cancel: {
            text: "No",
            value: null,
            visible: true,
            closeModal: true,
          },
        },
      }).then((quereEliminar) => {
        if (quereEliminar) {
          pacienteParam.activo = false;
          axios
            .post(this.$apiConfig.pacientesUrl, pacienteParam)
            .then(() => {
              this.$emit("onPacienteEliminado");
              this.$datosGuardados();
            })
            .catch((error) => {
              window.console.log(error);
            });
        }
      });
    },
    registrarCita(pacienteParam) {
      this.$emit("registrarCita", pacienteParam);
    },
    seleccionarPaciente(pacienteParam) {
      this.$emit("seleccionarPaciente", pacienteParam);
    },
    onBuscarPaciente() {
      this.$emit("onBuscarPaciente", this.busqueda);
    },
    cerrarModalConsultasAnteriores() {
      this.dialogConsultasAnteriores = false;
      this.getPacientesEnTurno();
    },
    mostrarModalConsultasAnteriores() {
      this.dialogConsultasAnteriores = true;
    },
    mostrarModalHistoriaClinica() {
      this.dialogHistoriaClinica = true;
      this.toggleHistoriaClinicaKey();
    },
    onHistoriaClinicaCancelada() {
      this.getConsultasAnteriores();
    },
    cerrarModalHistoriaClinica() {
      this.dialogHistoriaClinica = false;
      this.toggleHistoriaClinicaKey();
      this.getPacientesEnTurno();
    },
  },
  watch: {
    fechaDesde: function (newValue) {
      if (newValue !== null) {
        this.getPacientesEnTurno();
      }
    },
    fechaHasta: function (newValue) {
      if (newValue !== null) {
        this.getPacientesEnTurno();
      }
    },
  },
};
</script>

<style scoped v-lang="scss">
h1,
h2,
h3,
h4 {
  color: var(-v-primary-base);
}

#titulo-pacientes-cargados {
  margin-bottom: 30px;
}

.pacientes-container {
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 10px;
}

.action-button {
  color: #1c7494;
  border: 1px solid #1c7494;
  padding: 6px 8px;
  margin: 0px 1px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: bold;
}

.action-button:hover {
  background-color: rgba(28, 116, 148, 0.05);
}

.colorResaltado {
  color: #c52121;
}

@media screen and (min-width: 640px) {
  .action-button {
    width: 80px;
    margin: 0px 20px 0px 0px;
  }
}
</style>
