var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"id":"medicamentos-hc"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('v-menu',{ref:"menuFecha",attrs:{"return-value":_vm.fecha,"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.fecha=$event},"update:return-value":function($event){_vm.fecha=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Fecha","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFecha),callback:function ($$v) {_vm.menuFecha=$$v},expression:"menuFecha"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"click":function($event){_vm.menuFecha = false},"input":function($event){return _vm.$refs.menuFecha.save(_vm.fecha)}},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row'),_c('v-row',[_c('h5',[_vm._v("Medicamentos")])]),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.medicamentosHeader,"items":_vm.medicamentosFiltrados,"items-per-page":5},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"mx-4"},[_c('v-row',[_c('v-text-field',{ref:"txtBuscar",attrs:{"id":"txtBuscar","label":"Buscar"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.guardarMedicamento(_vm.searchMedicamento)}},model:{value:(_vm.searchMedicamento),callback:function ($$v) {_vm.searchMedicamento=$$v},expression:"searchMedicamento"}})],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editarMedicamento(item)}}},[_vm._v("mdi-pencil")])],1),_c('span',[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.indicarMedicamento(item)}}},[_vm._v("mdi-share")])],1)]}}])})],1),_c('v-btn',{staticClass:"mt-5",attrs:{"text":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.indicarDesdePlantilla()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-vector-selection")]),_vm._v("Indicar desde plantilla ")],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('h5',[_vm._v("Medicamentos indicados")])]),_c('v-row',[_c('v-simple-table',{attrs:{"fixed-header":"","height":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Id")]),_c('th',[_vm._v("Descripcion")]),_c('th',[_vm._v("Dosis")]),_c('th',[_vm._v("Vía")]),_c('th',[_vm._v("Uso")])])]),_c('tbody',_vm._l((_vm.medicamentosSeleccionados),function(item){return _c('tr',{key:item.id},[_c('td',[_c('label',[_vm._v(_vm._s(item.id))])]),_c('td',[_c('label',[_vm._v(_vm._s(item.descripcion))])]),_c('td',[_c('v-text-field',{attrs:{"label":"Dosis"},model:{value:(item.dosis),callback:function ($$v) {_vm.$set(item, "dosis", $$v)},expression:"item.dosis"}})],1),_c('td',[_c('v-text-field',{attrs:{"label":"Vía"},model:{value:(item.via),callback:function ($$v) {_vm.$set(item, "via", $$v)},expression:"item.via"}})],1),_c('td',[_c('v-text-field',{attrs:{"label":"Uso"},model:{value:(item.uso),callback:function ($$v) {_vm.$set(item, "uso", $$v)},expression:"item.uso"}})],1),_c('td',[_c('v-icon',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.eliminarMedicamentoIndicado(item)}}},[_vm._v("mdi-delete")])],1)])}),0)]},proxy:true},(
            _vm.medicamentosSeleccionados &&
            _vm.medicamentosSeleccionados.length > 0
          )?{key:"bottom",fn:function(){return [_c('v-row',{staticClass:"text-right"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"text":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.imprimir()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer")]),_vm._v("Imprimir ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"text":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.guardarComoPlantilla()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v("Guardar como plantilla ")],1)],1)],1)]},proxy:true}:null],null,true)})],1),_c('v-row',[_c('v-text-field',{staticClass:"mt-2",attrs:{"id":"txtObservacion","dense":"","outlined":"","label":"Observación (opcional)"},model:{value:(_vm.observacion),callback:function ($$v) {_vm.observacion=$$v},expression:"observacion"}})],1)],1),_c('registro-plantilla',{key:_vm.plantillakey,attrs:{"contexto":_vm.contexto,"dialog":_vm.dialogPlantilla},on:{"cerrarModal":function($event){return _vm.cerrarModalPlantilla()},"onPlantillaGuardada":_vm.reRenderListadoPlantillas,"update:dialog":function($event){_vm.dialogPlantilla=$event}}}),_c('listado-plantillas',{key:_vm.plantillaskey,attrs:{"dialog":_vm.dialogPlantillas,"contexto":_vm.contexto},on:{"update:dialog":function($event){_vm.dialogPlantillas=$event},"onItemSeleccionado":_vm.onPlantillaSeleccionada,"cerrarModal":function($event){return _vm.cerrarModalPlantillas()}}})],1)],1),_c('loading-dialog',{attrs:{"dialog":_vm.dialogImpresion,"mensaje":_vm.mensajeImpresion}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }